import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Features from '@solid-ui-blocks/Features/Block05'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import GatsbyConfig from '../../../gatsby-config';
import TextAndImg from '../../components/about/TextAndImg'
import Content from '../../components/about/Content'

const AboutUs = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Contact Us'
        description={``}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      <Header />
      <Container variant='full' sx={styles.heroContainer}>
        <Features content={content['hero']} reverse />
      </Container>
      <Divider space='5' />
      <TextAndImg content={content['content-one']} reverse />
      <Divider space='5' />
      <Content content={content['content-two']} />
      <Divider space='5' />
      <Content content={content['content-three']} />
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query innerpageAboutUsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/about"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default AboutUs
